import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'

export default ({
  data: {
    pagesJson: { author, description, image, metaTags, slug, title, components }
  },
  location,
  pageContext: { templates }
}) => {
  return <Layout location={location} title={title} description={description} image={image} author={author} metaTags={metaTags} slug={slug} components={components} templates={templates} />
}

export const query = graphql`
  query($id: String) {
    pagesJson(id: { eq: $id }) {
      author
      description
      image
      metaTags
      slug
      title
      components {
        name # dev-description
        description # dev-description
        datasource # dev-description
        id # dev-description
        sections {
          content
          id
        }
        template # all
        content # hero, overview, two-column-info
        jotFormId
        text
        heading1
        backgroundColor # CTA
        image # hero
        sharedTextButton {
          buttonType
          label
          link
        }
        disable_animations
        label # alternating-ordered-list basic-infographic, icon-grid, overview, process, two-column-info
        mainImage # basic-infographic
        imageText # basic-infographic
        statistic {
          # basic-infographic
          info
          statistic
          unit
        }
        headingTwoColumn {
          # two-column-info
          label
          content
        }
        chart {
          # two-column-info
          content
          statistic
        }
        icons {
          # two-column-info
          icon
          iconText
        }
        headingAnimated {
          # animated-infographic
          content
          label
        }
        animatedGraphic {
          # animated-infographic
          content
          graphic
          statistic
        }
        mainStatistic
        statistics {
          # animated-infographic
          info
          statistic
          unit
        }
        headingTeam {
          # team-members
          content
          label
        }
        member {
          # team-members
          image
          name
          titleLocation
        }
        orderedList {
          # alternating-ordered-list
          image
          icon
          label
          content
        }
        iconInfo {
          # icon-grid
          content
          icon
        }
        iconTextInfo {
          content
          icon
          label
        }
        article {
          # articles
          content
          icon
          label
        }
        # leftArrow # articles
        # rightArrow # articles
        bottomFive # REIT process
        bottomFour # REIT process
        bottomOne # REIT process
        bottomText # REIT process
        bottomTwo # REIT process
        iconFive # REIT process
        iconFour # REIT process
        iconOne # REIT process
        iconThree # REIT process
        iconTwo # REIT process
        topFour # REIT process
        topTwo # REIT process
        textThree # REIT process
        # details # Careers
        # position # Careers
        # requirements # Careers
        # summaryLabel # Careers
        cards {
          # CareerCarousel
          content # CareerCarousel
          icon # CareerCarousel
          title # CareerCarousel
        }
        imageX #hero, cta
        imageY #hero, cta
        mobileImageX #hero, cta
        mobileImageY #hero, cta
        subheading
      }
    }
  }
`
